html {
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding: 0;
}

body {
    background: #212020;
    min-height: 100vh;
}

#root {
    min-height: 100vh;
}